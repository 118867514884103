import {
  Box,
  BoxProps,
  Container,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useStep } from "../../components/Steps/useStep";
import { Fragment, Suspense, useEffect, useState } from "react";
import { Logo } from "../../layout/components/Logo";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../core/firebase";
import { IVerifiedInvite } from "../../types/IVerifiedInvite";
import { useSetRecoilState } from "recoil";
import { verifiedInviteState } from "../../state/ClientInvite";

export const LandingPage = (props: BoxProps) => {
  const { sourceId } = useParams();

  return (
    <Fragment>
      <Box as="nav" bg="bg-surface" boxShadow="sm">
        <Container py={{ base: "3", lg: "4" }}>
          <Flex alignItems={"center"} direction={{ base: "column", md: "row" }}>
            <Box flexGrow={0} py={{ base: "3", md: "0" }}>
              <Logo />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Container py={{ base: "6", md: "12" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="4" align="center">
          <Suspense fallback={<Spinner size="xl" />}>
            <Outlet />
          </Suspense>
        </Stack>
      </Container>
    </Fragment>
  );
};

export default LandingPage;
