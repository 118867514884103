import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Suspense } from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
// import { Rating } from "./Rating";
import { Outlet } from "react-router-dom";
import { verifiedInviteState } from "../../../state/ClientInvite";
import { useRecoilValue } from "recoil";
// import { useRecoilValueLoadable } from "recoil";
// import { verifiedInviteState } from "../../../state/ClientInvite";

export const Auth = () => {
  const invite = useRecoilValue(verifiedInviteState);

  return (
    <Box py={{ base: "6", md: "12" }} maxW="7xl" mx="auto">
      <Stack direction={{ base: "column", md: "column" }} spacing="12">
        <Center
          flex="1"
          px={{ lg: "8" }}
          display={{ base: "flex", lg: "flex" }}
        >
          <VStack spacing="6">
            <Heading size="sm" fontWeight="medium" textAlign="center">
              Welcome to EasyHome!
            </Heading>
            <Flex justify="center" align="center">
              <Avatar size="lg" src={invite?.agentPhotoUrl} />
              <Text ps="4">
                {invite?.agentName} invited you
                <br /> to join them on EasyHome.
              </Text>
            </Flex>
          </VStack>
        </Center>
        <Flex flex="1" justify="center">
          <Suspense>
            <Outlet />
          </Suspense>
        </Flex>
      </Stack>
    </Box>
  );
};
