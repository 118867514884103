import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AppLayout } from "../layout/AppLayout";
import { BaseLayout } from "../layout/BaseLayout";
import { RootError } from "../layout/RootError";
import { Root } from "./Root";
import { Auth } from "./AcceptInvite/Auth/Auth";
import { EmailForm } from "./AcceptInvite/Auth/EmailForm";
import { SignInForm } from "./AcceptInvite/Auth/SignInForm";
import { CreateAccountForm } from "./AcceptInvite/Auth/CreateAccountForm";
import { ResetPasswordForm } from "./AcceptInvite/Auth/ResetPasswordForm";
import { LandingPage } from "./LandingPage/LandingPage";
import { LandingEmailForm } from "./LandingPage/LandingAuth/EmailForm";
import { LandingAuth } from "./LandingPage/LandingAuth/Auth";
import { LandingSignInForm } from "./LandingPage/LandingAuth/SignInForm";
import { LandingCreateAccountForm } from "./LandingPage/LandingAuth/CreateAccountForm";
import { LandingResetPasswordForm } from "./LandingPage/LandingAuth/ResetPasswordForm";
import LandingProfile from "./LandingPage/LandingProfile";

const AcceptInvite = lazy(() => import("./AcceptInvite/AcceptInvite"));
const AppDownload = lazy(() => import("./AppDownload/AppDownload"));
const Welcome = lazy(() => import("./Welcome/Welcome"));
const InviteDetails = lazy(() => import("./InviteDetails/InviteDetails"));
// const SignIn = lazy(() => import("./auth/SignIn/SignIn"));
// const CreateAccount = lazy(() => import("./auth/CreateAccount/CreateAccount"));
// const ResetPassword = lazy(() => import("./auth/ResetPassword/ResetPassword"));

export const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <RootError />,
    children: [
      {
        path: "",
        index: true,
        element: <Navigate to="/invite/1234" />,
      },
      {
        path: "welcome",
        element: <LandingPage />,
        children: [
          {
            path: "",
            element: <LandingAuth />,
            children: [
              {
                path: "",
                element: <LandingEmailForm />,
              },
              {
                path: "sign-in",
                element: <LandingSignInForm />,
              },
              {
                path: "create-account",
                element: <LandingCreateAccountForm />,
              },
              {
                path: "reset-password",
                element: <LandingResetPasswordForm />,
              },
              {
                path: "profile",
                element: <LandingProfile />,
              },
            ],
          },

          { path: "details", element: <InviteDetails /> },
          { path: "download", element: <AppDownload /> },
        ],
      },
      {
        path: "invite/:inviteId",
        element: <AcceptInvite />,
        children: [
          {
            path: "",
            element: <Auth />,
            children: [
              {
                path: "",
                element: <EmailForm />,
              },
              {
                path: "sign-in",
                element: <SignInForm />,
              },
              {
                path: "create-account",
                element: <CreateAccountForm />,
              },
              {
                path: "reset-password",
                element: <ResetPasswordForm />,
              },
            ],
          },

          { path: "details", element: <InviteDetails /> },
          { path: "download", element: <AppDownload /> },
        ],
      },
    ],
  },
]);
