import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const LogoIcon = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    color="accent"
    height="12"
    width="auto"
    viewBox="0 0 156 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.0282 23.2259H48.2939C49.3149 23.2259 49.7039 23.0539 50.0443 22.7345C50.5305 22.2185 50.725 21.162 50.725 20.7197C50.725 19.9089 50.0929 19.3684 48.8773 19.3684H42.6781L43.1643 16.985H47.6618C48.6099 16.985 48.9503 16.7885 49.2663 16.4936C49.7282 16.0268 49.8984 15.044 49.8984 14.6509C49.8984 13.8892 49.3149 13.3732 48.1966 13.3732H43.9179L44.3798 11.211H50.6763C51.6974 11.211 52.0864 11.0636 52.4024 10.7442C52.9129 10.2282 53.1317 9.17168 53.1317 8.70484C53.1317 7.86945 52.4753 7.32891 51.2598 7.32891H42.7996C41.2194 7.32891 40.1983 8.04145 39.8337 9.76137L37.6457 20.13C37.2081 22.2677 38.0833 23.2259 40.0282 23.2259Z"
      fill="currentColor"
    />
    <path
      d="M55.8718 23.5208C57.7437 23.5208 59.251 22.5134 60.1019 21.0146C60.1505 21.5551 60.272 22.022 60.5152 22.4151C60.9527 23.1031 61.755 23.5208 62.9706 23.5208C65.0856 23.5208 65.4503 22.2185 65.4016 22.194C64.5751 21.9237 64.332 21.3586 64.648 19.9335L66.4227 11.5059C66.447 11.3339 65.742 10.9407 64.8425 10.9407C64.0889 10.9407 63.4568 11.0882 62.9462 11.4076C62.4357 11.727 62.0224 12.2184 61.7064 12.9309C61.0257 11.4813 59.5184 10.7442 57.9868 10.7442C54.0728 10.7442 51.4715 14.6017 51.4715 18.6558C51.4715 21.6288 53.2705 23.5208 55.8718 23.5208ZM57.7923 19.8598C56.6983 19.8598 55.9447 19.049 55.9447 17.845C55.9447 16.0268 57.0387 14.3069 58.7891 14.3069C59.9074 14.3069 60.5152 14.9211 60.8555 15.8548L60.5638 17.329C60.2477 18.9015 59.1294 19.8598 57.7923 19.8598Z"
      fill="currentColor"
    />
    <path
      d="M71.7409 23.4962C75.266 23.4962 77.0893 21.85 77.0893 19.4912C77.0893 17.4273 75.3633 16.5674 73.4427 15.8057C72.4703 15.4125 71.7896 15.1423 71.7896 14.6509C71.7896 14.2086 72.2028 13.8646 72.9808 13.8646C74.3422 13.8646 75.655 14.7737 76.4816 15.8057C76.5788 15.904 78.0131 15.2897 78.0131 13.8155C78.0131 13.2504 77.7943 12.7835 77.4054 12.415C76.4329 11.4322 74.7312 10.7933 72.6891 10.7933C69.2855 10.7933 67.5352 12.6361 67.5352 14.872C67.5352 17.157 69.2855 17.9433 70.9387 18.6558C71.7652 18.9507 72.3001 19.2209 72.3001 19.6386C72.3001 20.1546 71.9111 20.4495 71.1575 20.4495C69.7474 20.4495 68.1672 19.5404 67.2434 18.533C67.1462 18.4101 65.9063 19.0735 65.9063 20.3266C65.9063 20.9163 66.1981 21.4568 66.66 21.8991C67.5352 22.8082 69.2855 23.4962 71.7409 23.4962Z"
      fill="currentColor"
    />
    <path
      d="M88.5992 10.8179L84.4177 18.2136L84.1989 14.9948C84.126 13.5206 83.81 12.4641 83.2751 11.8007C82.7646 11.1127 82.0353 10.7933 81.1358 10.7933C78.6804 10.7933 78.2185 12.8572 78.3157 12.8818C79.1909 13.0047 79.823 13.5452 80.0418 15.3143L80.7468 20.8672C80.8683 21.6534 81.2087 22.1202 81.7678 22.2922L81.5247 22.6362C80.4794 24.2087 79.7744 24.6018 78.8992 24.6018C78.4373 24.6018 77.8295 24.4053 77.5864 24.1596C77.5134 24.135 76.857 24.9213 76.857 25.7567C76.857 26.4201 77.0515 26.936 77.4648 27.3292C77.9997 27.8943 78.8992 28.1645 80.0904 28.1645C82.3513 28.1645 83.737 26.6658 85.1957 24.3316L90.8844 15.2897C91.5165 14.3069 91.7596 13.7909 91.7596 13.0784C91.7596 11.039 88.6478 10.7688 88.5992 10.8179Z"
      fill="currentColor"
    />
    <path
      d="M93.018 23.3488H93.723C95.133 23.3488 95.8137 22.5871 96.1297 21.0883L96.9563 17.2799H101.94L101.138 21.0637C100.822 22.5625 101.575 23.3488 102.912 23.3488H103.593C105.003 23.3488 105.684 22.6362 106 21.1129L108.455 9.54023C108.771 8.01687 108.018 7.20605 106.681 7.20605H105.976C104.59 7.20605 103.909 7.94316 103.593 9.46653L102.791 13.1766H97.8072L98.5851 9.4911C98.9012 7.96773 98.1476 7.20605 96.7861 7.20605H96.0811C94.7197 7.20605 94.039 7.91859 93.6987 9.44195L91.2676 21.0146C90.9515 22.5379 91.7052 23.3488 93.018 23.3488Z"
      fill="currentColor"
    />
    <path
      d="M113.742 23.5453C118.166 23.5453 120.597 20.0072 120.597 16.3216C120.597 13.2749 118.628 10.7442 114.69 10.7442C110.241 10.7442 107.834 14.2823 107.834 17.9679C107.834 21.0146 109.803 23.5453 113.742 23.5453ZM113.839 20.0809C112.599 20.0809 111.724 19.0981 111.724 17.7713C111.724 15.9531 112.842 14.184 114.592 14.184C115.832 14.184 116.707 15.1914 116.707 16.5182C116.707 18.3364 115.589 20.0809 113.839 20.0809Z"
      fill="currentColor"
    />
    <path
      d="M139.111 23.5208C141.494 23.5208 141.931 21.8745 141.858 21.85C141.251 21.7026 140.91 21.4077 140.91 20.6952C140.91 20.2038 141.153 19.2455 141.47 18.0661C141.907 16.4936 142.15 15.4371 142.15 14.5772C142.15 12.3904 140.813 10.7442 138.601 10.7442C136.68 10.7442 135.319 11.8007 134.298 13.3486C133.909 11.7516 132.572 10.7442 130.894 10.7442C129.071 10.7442 127.734 11.7024 126.64 13.2504C126.616 12.9555 126.543 12.6852 126.494 12.4641C126.081 11.3584 125.157 10.9407 124.16 10.9407C123.431 10.9407 122.847 11.1373 122.823 11.2602L120.757 21.0883C120.392 22.8082 121.51 23.3488 122.75 23.3488H123.236C124.428 23.3488 125.06 22.7836 125.4 21.0883L126.591 15.4617C127.126 14.8474 127.588 14.5526 128.293 14.5526C129.217 14.5526 129.679 15.4125 129.436 16.6165L128.488 21.0883C128.123 22.8328 129.241 23.3488 130.481 23.3488H130.967C132.158 23.3488 132.766 22.7836 133.131 21.0883L134.298 15.5354C134.833 14.8474 135.343 14.5526 136 14.5526C136.753 14.5526 137.264 15.1177 137.264 16.0268C137.264 16.4199 137.094 17.2308 136.802 18.2627C136.413 19.6878 136.267 20.4003 136.267 21.1374C136.267 22.5625 137.094 23.5208 139.111 23.5208Z"
      fill="currentColor"
    />
    <path
      d="M148.984 23.5453C151.124 23.5453 152.607 22.9311 153.385 22.1448C153.895 21.6288 154.138 21.162 154.138 20.5477C154.138 19.0981 152.315 18.8524 152.291 18.9015C151.61 19.7861 150.467 20.4003 149.057 20.4003C147.842 20.4003 147.064 19.8598 146.821 18.9998C146.699 18.8278 146.626 18.6313 146.578 18.4101C147.283 18.4593 147.915 18.4593 148.522 18.4593C153.846 18.4593 155.427 16.8622 155.427 14.4789C155.427 12.2675 153.676 10.7442 150.346 10.7442C146.164 10.7442 142.785 13.7418 142.785 18.2136C142.785 21.2603 144.706 23.5453 148.984 23.5453ZM146.991 16.0268C147.55 14.6754 148.717 13.6681 150.151 13.6681C151.172 13.6681 151.683 14.1595 151.683 14.7C151.683 15.5108 150.783 16.0759 148.109 16.0759C147.769 16.0759 147.404 16.0759 146.991 16.0268Z"
      fill="currentColor"
    />
    <path
      d="M16.4075 13.8034C16.4075 13.8034 12.6674 11.9396 10.5188 11.0077C8.37024 10.0758 7.41486 11.5513 8.5294 12.9492C9.64393 14.347 13.801 18.0393 14.7364 18.8507C16.1688 20.0932 17.2775 19.7281 18.4765 18.8507C20.068 17.686 30.7313 10.0286 33.0391 8.13582C34.2891 7.11056 33.3574 5.26231 31.2884 6.1167C29.2194 6.97109 16.4075 13.8034 16.4075 13.8034Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.481 7.01254L16.2469 3.30424L3.78372 8.91713C3.55471 9.02027 3.40741 9.24834 3.40741 9.4998V18.3548C3.40741 19.5897 4.40713 20.5908 5.64036 20.5908H9.88838C10.92 20.5908 11.9145 20.9759 12.6779 21.6708L16.314 24.981L20.3821 21.5625C21.129 20.9349 22.0728 20.5908 23.0478 20.5908H26.8534C28.0866 20.5908 29.0863 19.5897 29.0863 18.3548V13.5762C29.0928 13.5709 29.0993 13.5656 29.1058 13.5604C30.0098 12.8792 30.8725 12.2253 31.6383 11.6388V18.3548C31.6383 21.0011 29.496 23.1464 26.8534 23.1464H23.0478C22.6728 23.1464 22.3098 23.2787 22.0225 23.5201L16.6738 28.0148C16.4292 28.2203 16.0707 28.2131 15.8345 27.9981L10.9613 23.5617C10.6677 23.2945 10.2851 23.1464 9.88838 23.1464H5.64036C2.99774 23.1464 0.855469 21.0011 0.855469 18.3548V9.4998C0.855469 8.2425 1.59196 7.10213 2.73703 6.58644L15.5927 0.79678C16.0087 0.609407 16.485 0.609407 16.901 0.79678L27.3459 5.50072C26.4724 5.95552 25.4909 6.4744 24.481 7.01254ZM27.1084 8.19583L28.71 8.91713C28.939 9.02027 29.0863 9.24834 29.0863 9.4998V10.8992C30.0321 10.1206 30.8863 9.39728 31.5596 8.79423C31.3517 7.87469 30.7442 7.08215 29.8868 6.64859C29.1312 7.05418 28.1682 7.59253 27.1084 8.19583Z"
      fill="currentColor"
    />
  </chakra.svg>
);

export const Logo = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    color="accent"
    height="auto"
    width="40"
    viewBox="0 0 156 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.0282 23.2259H48.2939C49.3149 23.2259 49.7039 23.0539 50.0443 22.7345C50.5305 22.2185 50.725 21.162 50.725 20.7197C50.725 19.9089 50.0929 19.3684 48.8773 19.3684H42.6781L43.1643 16.985H47.6618C48.6099 16.985 48.9503 16.7885 49.2663 16.4936C49.7282 16.0268 49.8984 15.044 49.8984 14.6509C49.8984 13.8892 49.3149 13.3732 48.1966 13.3732H43.9179L44.3798 11.211H50.6763C51.6974 11.211 52.0864 11.0636 52.4024 10.7442C52.9129 10.2282 53.1317 9.17168 53.1317 8.70484C53.1317 7.86945 52.4753 7.32891 51.2598 7.32891H42.7996C41.2194 7.32891 40.1983 8.04145 39.8337 9.76137L37.6457 20.13C37.2081 22.2677 38.0833 23.2259 40.0282 23.2259Z"
      fill="currentColor"
    />
    <path
      d="M55.8718 23.5208C57.7437 23.5208 59.251 22.5134 60.1019 21.0146C60.1505 21.5551 60.272 22.022 60.5152 22.4151C60.9527 23.1031 61.755 23.5208 62.9706 23.5208C65.0856 23.5208 65.4503 22.2185 65.4016 22.194C64.5751 21.9237 64.332 21.3586 64.648 19.9335L66.4227 11.5059C66.447 11.3339 65.742 10.9407 64.8425 10.9407C64.0889 10.9407 63.4568 11.0882 62.9462 11.4076C62.4357 11.727 62.0224 12.2184 61.7064 12.9309C61.0257 11.4813 59.5184 10.7442 57.9868 10.7442C54.0728 10.7442 51.4715 14.6017 51.4715 18.6558C51.4715 21.6288 53.2705 23.5208 55.8718 23.5208ZM57.7923 19.8598C56.6983 19.8598 55.9447 19.049 55.9447 17.845C55.9447 16.0268 57.0387 14.3069 58.7891 14.3069C59.9074 14.3069 60.5152 14.9211 60.8555 15.8548L60.5638 17.329C60.2477 18.9015 59.1294 19.8598 57.7923 19.8598Z"
      fill="currentColor"
    />
    <path
      d="M71.7409 23.4962C75.266 23.4962 77.0893 21.85 77.0893 19.4912C77.0893 17.4273 75.3633 16.5674 73.4427 15.8057C72.4703 15.4125 71.7896 15.1423 71.7896 14.6509C71.7896 14.2086 72.2028 13.8646 72.9808 13.8646C74.3422 13.8646 75.655 14.7737 76.4816 15.8057C76.5788 15.904 78.0131 15.2897 78.0131 13.8155C78.0131 13.2504 77.7943 12.7835 77.4054 12.415C76.4329 11.4322 74.7312 10.7933 72.6891 10.7933C69.2855 10.7933 67.5352 12.6361 67.5352 14.872C67.5352 17.157 69.2855 17.9433 70.9387 18.6558C71.7652 18.9507 72.3001 19.2209 72.3001 19.6386C72.3001 20.1546 71.9111 20.4495 71.1575 20.4495C69.7474 20.4495 68.1672 19.5404 67.2434 18.533C67.1462 18.4101 65.9063 19.0735 65.9063 20.3266C65.9063 20.9163 66.1981 21.4568 66.66 21.8991C67.5352 22.8082 69.2855 23.4962 71.7409 23.4962Z"
      fill="currentColor"
    />
    <path
      d="M88.5992 10.8179L84.4177 18.2136L84.1989 14.9948C84.126 13.5206 83.81 12.4641 83.2751 11.8007C82.7646 11.1127 82.0353 10.7933 81.1358 10.7933C78.6804 10.7933 78.2185 12.8572 78.3157 12.8818C79.1909 13.0047 79.823 13.5452 80.0418 15.3143L80.7468 20.8672C80.8683 21.6534 81.2087 22.1202 81.7678 22.2922L81.5247 22.6362C80.4794 24.2087 79.7744 24.6018 78.8992 24.6018C78.4373 24.6018 77.8295 24.4053 77.5864 24.1596C77.5134 24.135 76.857 24.9213 76.857 25.7567C76.857 26.4201 77.0515 26.936 77.4648 27.3292C77.9997 27.8943 78.8992 28.1645 80.0904 28.1645C82.3513 28.1645 83.737 26.6658 85.1957 24.3316L90.8844 15.2897C91.5165 14.3069 91.7596 13.7909 91.7596 13.0784C91.7596 11.039 88.6478 10.7688 88.5992 10.8179Z"
      fill="currentColor"
    />
    <path
      d="M93.018 23.3488H93.723C95.133 23.3488 95.8137 22.5871 96.1297 21.0883L96.9563 17.2799H101.94L101.138 21.0637C100.822 22.5625 101.575 23.3488 102.912 23.3488H103.593C105.003 23.3488 105.684 22.6362 106 21.1129L108.455 9.54023C108.771 8.01687 108.018 7.20605 106.681 7.20605H105.976C104.59 7.20605 103.909 7.94316 103.593 9.46653L102.791 13.1766H97.8072L98.5851 9.4911C98.9012 7.96773 98.1476 7.20605 96.7861 7.20605H96.0811C94.7197 7.20605 94.039 7.91859 93.6987 9.44195L91.2676 21.0146C90.9515 22.5379 91.7052 23.3488 93.018 23.3488Z"
      fill="currentColor"
    />
    <path
      d="M113.742 23.5453C118.166 23.5453 120.597 20.0072 120.597 16.3216C120.597 13.2749 118.628 10.7442 114.69 10.7442C110.241 10.7442 107.834 14.2823 107.834 17.9679C107.834 21.0146 109.803 23.5453 113.742 23.5453ZM113.839 20.0809C112.599 20.0809 111.724 19.0981 111.724 17.7713C111.724 15.9531 112.842 14.184 114.592 14.184C115.832 14.184 116.707 15.1914 116.707 16.5182C116.707 18.3364 115.589 20.0809 113.839 20.0809Z"
      fill="currentColor"
    />
    <path
      d="M139.111 23.5208C141.494 23.5208 141.931 21.8745 141.858 21.85C141.251 21.7026 140.91 21.4077 140.91 20.6952C140.91 20.2038 141.153 19.2455 141.47 18.0661C141.907 16.4936 142.15 15.4371 142.15 14.5772C142.15 12.3904 140.813 10.7442 138.601 10.7442C136.68 10.7442 135.319 11.8007 134.298 13.3486C133.909 11.7516 132.572 10.7442 130.894 10.7442C129.071 10.7442 127.734 11.7024 126.64 13.2504C126.616 12.9555 126.543 12.6852 126.494 12.4641C126.081 11.3584 125.157 10.9407 124.16 10.9407C123.431 10.9407 122.847 11.1373 122.823 11.2602L120.757 21.0883C120.392 22.8082 121.51 23.3488 122.75 23.3488H123.236C124.428 23.3488 125.06 22.7836 125.4 21.0883L126.591 15.4617C127.126 14.8474 127.588 14.5526 128.293 14.5526C129.217 14.5526 129.679 15.4125 129.436 16.6165L128.488 21.0883C128.123 22.8328 129.241 23.3488 130.481 23.3488H130.967C132.158 23.3488 132.766 22.7836 133.131 21.0883L134.298 15.5354C134.833 14.8474 135.343 14.5526 136 14.5526C136.753 14.5526 137.264 15.1177 137.264 16.0268C137.264 16.4199 137.094 17.2308 136.802 18.2627C136.413 19.6878 136.267 20.4003 136.267 21.1374C136.267 22.5625 137.094 23.5208 139.111 23.5208Z"
      fill="currentColor"
    />
    <path
      d="M148.984 23.5453C151.124 23.5453 152.607 22.9311 153.385 22.1448C153.895 21.6288 154.138 21.162 154.138 20.5477C154.138 19.0981 152.315 18.8524 152.291 18.9015C151.61 19.7861 150.467 20.4003 149.057 20.4003C147.842 20.4003 147.064 19.8598 146.821 18.9998C146.699 18.8278 146.626 18.6313 146.578 18.4101C147.283 18.4593 147.915 18.4593 148.522 18.4593C153.846 18.4593 155.427 16.8622 155.427 14.4789C155.427 12.2675 153.676 10.7442 150.346 10.7442C146.164 10.7442 142.785 13.7418 142.785 18.2136C142.785 21.2603 144.706 23.5453 148.984 23.5453ZM146.991 16.0268C147.55 14.6754 148.717 13.6681 150.151 13.6681C151.172 13.6681 151.683 14.1595 151.683 14.7C151.683 15.5108 150.783 16.0759 148.109 16.0759C147.769 16.0759 147.404 16.0759 146.991 16.0268Z"
      fill="currentColor"
    />
    <path
      d="M16.4075 13.8034C16.4075 13.8034 12.6674 11.9396 10.5188 11.0077C8.37024 10.0758 7.41486 11.5513 8.5294 12.9492C9.64393 14.347 13.801 18.0393 14.7364 18.8507C16.1688 20.0932 17.2775 19.7281 18.4765 18.8507C20.068 17.686 30.7313 10.0286 33.0391 8.13582C34.2891 7.11056 33.3574 5.26231 31.2884 6.1167C29.2194 6.97109 16.4075 13.8034 16.4075 13.8034Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.481 7.01254L16.2469 3.30424L3.78372 8.91713C3.55471 9.02027 3.40741 9.24834 3.40741 9.4998V18.3548C3.40741 19.5897 4.40713 20.5908 5.64036 20.5908H9.88838C10.92 20.5908 11.9145 20.9759 12.6779 21.6708L16.314 24.981L20.3821 21.5625C21.129 20.9349 22.0728 20.5908 23.0478 20.5908H26.8534C28.0866 20.5908 29.0863 19.5897 29.0863 18.3548V13.5762C29.0928 13.5709 29.0993 13.5656 29.1058 13.5604C30.0098 12.8792 30.8725 12.2253 31.6383 11.6388V18.3548C31.6383 21.0011 29.496 23.1464 26.8534 23.1464H23.0478C22.6728 23.1464 22.3098 23.2787 22.0225 23.5201L16.6738 28.0148C16.4292 28.2203 16.0707 28.2131 15.8345 27.9981L10.9613 23.5617C10.6677 23.2945 10.2851 23.1464 9.88838 23.1464H5.64036C2.99774 23.1464 0.855469 21.0011 0.855469 18.3548V9.4998C0.855469 8.2425 1.59196 7.10213 2.73703 6.58644L15.5927 0.79678C16.0087 0.609407 16.485 0.609407 16.901 0.79678L27.3459 5.50072C26.4724 5.95552 25.4909 6.4744 24.481 7.01254ZM27.1084 8.19583L28.71 8.91713C28.939 9.02027 29.0863 9.24834 29.0863 9.4998V10.8992C30.0321 10.1206 30.8863 9.39728 31.5596 8.79423C31.3517 7.87469 30.7442 7.08215 29.8868 6.64859C29.1312 7.05418 28.1682 7.59253 27.1084 8.19583Z"
      fill="currentColor"
    />
  </chakra.svg>
);
