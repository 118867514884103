import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../../core/firebase";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Heading,
  Stack,
  StackProps,
  Container,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { PasswordField } from "../../../components/PasswordField";
import { useEffect, useState } from "react";
import { createUserWithInvite } from "../../../services/ApiService";

export const CreateAccountForm = (props: StackProps) => {
  const { inviteId } = useParams();
  const [email, setEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [createUser, loading, error] = useCreateUserWithEmailAndPassword(auth);

  const onSubmit = async (data: any) => {
    try {
      const result = await createUser(email || "", data.password);
      if (result && result.user) {
        // create a user record in the database
        await createUserWithInvite(result.user, inviteId || "");
        const inviteDetailsPath = generatePath("/invite/:inviteId/details", {
          inviteId: inviteId || "",
        });
        navigate(inviteDetailsPath);
      }
    } catch (error) {
      setError("root.submissionError", { message: "An error occurred." });
    }
  };

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxW="md"
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg-surface" }}
        boxShadow={{ base: "none", sm: "md" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs" }}>
                Your email is confirmed.
                <br />
                Let's secure your account.
              </Heading>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Text>Confirmed email: {email}</Text>
            <PasswordField
              {...register("password", { required: true })}
              isInvalid={errors?.password ? true : false}
              title="Choose a password"
            />
            {errors.password && (
              <Text pt={1} color="error" fontSize="sm" role="alert">
                This field is required
              </Text>
            )}
            <Text fontSize="xs" lineHeight={1.3}>
              By clicking "Continue" you agree to EasyHome's{" "}
              <ChakraLink
                as={Link}
                to="https://www.easyhomeinc.com/terms"
                color="teal.500"
                target="_blank"
              >
                Terms of Use
              </ChakraLink>{" "}
              and{" "}
              <ChakraLink
                color="teal.500"
                as={Link}
                to="https://www.easyhomeinc.com/privacy"
                target="_blank"
              >
                Privacy Policy
              </ChakraLink>
              .
            </Text>
            <Button variant="primary" isLoading={isSubmitting} type="submit">
              Continue
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
