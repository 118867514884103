import { db } from "../core/firebase";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { User } from "firebase/auth";
import { IClientInvite } from "../types/IClientInvite";

export const createUserWithInvite = async (user: User, inviteId: string) => {
  console.log("creating user: ", user.uid);
  console.log("with email: ", user.email);
  console.log("and inviteId: ", inviteId);

  const userRef = doc(db, "users", user.uid);
  await setDoc(userRef, {
    id: user.uid,
    email: user.email,
    inviteId: inviteId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    surveyLeadSource: "Client Connect Invite",
    nextOnboardingRoute: "/ob-survey1-view",
  });
};
