import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const LandingAuth = () => {
  return (
    <Box py={{ base: "6", md: "12" }} maxW="7xl" mx="auto">
      <Stack direction={{ base: "column", md: "column" }} spacing="12">
        <Flex flex="1" justify="center">
          <Suspense>
            <Outlet />
          </Suspense>
        </Flex>
      </Stack>
    </Box>
  );
};
