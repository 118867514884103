import { Suspense, createContext } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { userIdState } from "../state/User";

export const UserIdContext = createContext<string | null>(null);

export const Root = () => {
  const userId = useRecoilValueLoadable(userIdState);

  if (userId.state === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <UserIdContext.Provider value={userId.valueOrThrow()}>
      <Suspense>
        <Outlet />
      </Suspense>
    </UserIdContext.Provider>
  );
};
