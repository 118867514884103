import {
  useSendPasswordResetEmail,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { auth } from "../../../core/firebase";

import { Link, useNavigate } from "react-router-dom";
// import { FIREBASE_ERRORS } from "../../../config/errors";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  Link as ChakraLink,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Logo } from "../../../layout/components/Logo";
import { useForm } from "react-hook-form";
import { useState } from "react";
// import { useSignInWithEmailPassword } from "../../state/CurrentUser";

export const LandingResetPasswordForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [sendPasswordResetEmail, loading, error] =
    useSendPasswordResetEmail(auth);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      await sendPasswordResetEmail(data.email);
      setShowSuccessMessage(true);
    } catch (error) {
      setError("root.submissionError", { message: "An error occurred." });
    }
  };

  return (
    <Stack spacing="8">
      <Stack spacing="6" align="center">
        {isMobile && <Logo />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "xs", md: "sm" }}>Reset your password</Heading>
        </Stack>
        <HStack spacing="1" justify="center">
          <Text color="muted">Back to </Text>
          <ChakraLink color="teal.500" as={Link} to="sign-in">
            Sign In
          </ChakraLink>
        </HStack>
      </Stack>
      {errors.root && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>
            {errors.root.submissionError.message}
          </AlertDescription>
        </Alert>
      )}
      {!showSuccessMessage && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  {...register("email", { required: true })}
                />
              </FormControl>
            </Stack>

            <Stack spacing="4">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Submit
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
      {showSuccessMessage && (
        <Stack spacing="4">
          <Text>
            If an account exists with that email, we have sent you an email with
            a link to reset your password.
          </Text>
        </Stack>
      )}
    </Stack>
  );
};
