import { User } from "firebase/auth";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { atom, selector } from "recoil";
import { auth, db } from "../core/firebase";

export const userIdState = atom<string | null>({
  key: "UserId",
  effects: [
    (ctx) => {
      if (ctx.trigger === "get") {
        return auth.onAuthStateChanged((user: User | null) => {
          if (user) {
            ctx.setSelf(user.uid);
          } else {
            ctx.setSelf(null);
          }
        });
      }
    },
  ],
});

export const userProfileState = selector<DocumentData>({
  key: "UserProfile",
  get: async ({ get }) => {
    const userId = get(userIdState);
    if (userId) {
      const docRef = await getDoc(doc(db, "users", userId));
      return docRef.data();
    } else {
      return null;
    }
  },
});
