import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../../core/firebase";

import { Link, generatePath, useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link as ChakraLink,
  Stack,
  StackProps,
  Text,
  Container,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export const SignInForm = (props: StackProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { inviteId } = useParams();
  const [email, setEmail] = useState<string | null>(null);
  const [signIn, loading, error] = useSignInWithEmailAndPassword(auth);

  const onSubmit = async (data: any) => {
    try {
      const result = await signIn(email || "", data.password);
      if (result && result.user) {
        const inviteDetailsPath = generatePath("/invite/:inviteId/details", {
          inviteId: inviteId || "",
        });
        navigate(inviteDetailsPath);
      } else {
        setError("root.submissionError", {
          message: "The password was incorrect. Please try again.",
        });
      }
    } catch (error) {
      setError("root.submissionError", { message: "An error occurred." });
    }
  };

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxW="md"
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg-surface" }}
        boxShadow={{ base: "none", sm: "md" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs" }}>Sign in to EasyHome</Heading>
              <Text>
                Good news, we found your account. Please sign in to continue.
              </Text>
            </Stack>
          </Stack>
          {errors.root && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {errors.root.submissionError.message}
              </AlertDescription>
            </Alert>
          )}
          <Stack spacing="6">
            <Text>Using email {email}</Text>
            <FormControl>
              <FormLabel htmlFor="password">Enter your password</FormLabel>
              <Input
                id="password"
                placeholder="Password"
                isInvalid={errors?.password ? true : false}
                type="password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <Text pt={1} color="error" fontSize="sm" role="alert">
                  This field is required
                </Text>
              )}
            </FormControl>
            <Text fontSize="xs" lineHeight={1.3}>
              By clicking "Continue" you agree to EasyHome's{" "}
              <ChakraLink
                as={Link}
                to="https://www.easyhomeinc.com/terms"
                color="teal.500"
                target="_blank"
              >
                Terms of Use
              </ChakraLink>{" "}
              and{" "}
              <ChakraLink
                color="teal.500"
                as={Link}
                to="https://www.easyhomeinc.com/privacy"
                target="_blank"
              >
                Privacy Policy
              </ChakraLink>
              .
            </Text>
            <Button variant="primary" isLoading={isSubmitting} type="submit">
              Continue
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
