import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Link as ChakraLink,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../core/firebase";
import { IClientInvite } from "../../types/IClientInvite";
import { useRecoilValue } from "recoil";
import { userIdState, userProfileState } from "../../state/User";
import Autocomplete from "react-google-autocomplete";
import { config } from "../../core/config";
import { formatGooglePlaceAddress } from "../../core/addressUtils";
import { IAddress } from "../../types/IAddress";

const LandingProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newPlace, setNewPlace] = useState<any>(null);
  const userId = useRecoilValue(userIdState);
  const userProfile = useRecoilValue(userProfileState);
  const navigate = useNavigate();
  const [clientInvite, setClientInvite] = useState<IClientInvite | null>(null);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      let address: IAddress | null = null;
      if (newPlace !== null) {
        // sometime the city (locality) is null, make sure we have a fallback
        let city = newPlace.address_components?.find(
          (c: any) => c.types[0] === "locality"
        )?.long_name;
        if (typeof city === "undefined") {
          city = newPlace.address_components?.find(
            (c: any) => c.types[0] === "sublocality_level_1"
          )?.long_name;
        }

        address = {
          googlePlaceId: newPlace.place_id,
          streetNumber: newPlace.address_components?.find(
            (c: any) => c.types[0] === "street_number"
          )?.long_name,
          streetShort: newPlace.address_components?.find(
            (c: any) => c.types[0] === "route"
          )?.short_name,
          streetLong: newPlace.address_components?.find(
            (c: any) => c.types[0] === "route"
          )?.long_name,
          city: city,
          state: newPlace.address_components?.find(
            (c: any) => c.types[0] === "administrative_area_level_1"
          )?.short_name,
          postalCode: newPlace.address_components?.find(
            (c: any) => c.types[0] === "postal_code"
          )?.long_name,
          latitude: newPlace.geometry?.location.lat(),
          longitute: newPlace.geometry?.location.lng(),
          unit: data.unit,
        };
      }

      // make a call to the server to configure this new home and update the invite record
      // const claimInvite = httpsCallable(functions, "claimInvite");
      // const inviteParams = {
      //   inviteRef: clientInvite?.ref,
      //   firstName: data.firstName.trim(),
      //   lastName: data.lastName.trim(),
      //   phone: data.phone.trim(),
      //   newAddress: address,
      //   unit: data.unit.trim(),
      // };
      // const result = await claimInvite(inviteParams);
      // if (result?.data === true) {
      //   const downloadPath = generatePath("/invite/:inviteId/download", {
      //     inviteId: inviteId || "",
      //   });
      //   navigate(downloadPath);
      // }
    } catch (error) {
      setError("root.submissionError", { message: "An error occurred." });
    }
  };

  const formatAddress = (address?: IAddress) => {
    if (address) {
      const returnAddress = formatGooglePlaceAddress(address);
      return returnAddress;
    }
    return "";
  };

  // useEffect(() => {
  //   if (userId === null) {
  //     const invitePath = generatePath("/invite/:inviteId", {
  //       inviteId: inviteId || "",
  //     });
  //     navigate(invitePath);
  //     return;
  //   }

  //   const getInviteDetails = httpsCallable(functions, "getInviteDetails");
  //   getInviteDetails({ inviteId: inviteId, email: userProfile?.email }).then(
  //     (result) => {
  //       const data: unknown = result.data;
  //       setIsLoading(false);
  //       if (data !== null && typeof data === "object") {
  //         const obj = data as IClientInvite;
  //         console.log(obj);
  //         setClientInvite(obj);
  //       }
  //     }
  //   );

  //   reset();
  // }, [reset]);

  return (
    <Container
      maxW="xlg"
      py={{ base: "6", md: "8" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8" align="center">
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "xs", md: "sm" }}>Create your profile</Heading>
          {/* <Text>Please enter your profile info.</Text> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={{ base: "8", lg: "6" }}>
              <Box bg="bg-surface" boxShadow="sm" borderRadius="lg">
                <Stack
                  spacing="4"
                  px={{ base: "4", md: "6" }}
                  py={{ base: "5", md: "6" }}
                >
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl id="firstName">
                      <FormLabel>First Name</FormLabel>
                      <Input
                        isInvalid={errors?.firstName ? true : false}
                        {...register("firstName", { required: true })}
                      />
                      {errors.firstName && (
                        <Text pt={1} color="error" fontSize="sm" role="alert">
                          This field is required
                        </Text>
                      )}
                    </FormControl>
                    <FormControl id="lastName">
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        isInvalid={errors?.lastName ? true : false}
                        {...register("lastName", { required: true })}
                      />
                      {errors.lastName && (
                        <Text pt={1} color="error" fontSize="sm" role="alert">
                          This field is required
                        </Text>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl id="email">
                      <FormLabel>Email</FormLabel>
                      <Input
                        disabled={true}
                        defaultValue={clientInvite?.email}
                      />
                    </FormControl>
                    <FormControl id="phone">
                      <FormLabel>Phone</FormLabel>
                      <Input
                        isInvalid={errors?.phone ? true : false}
                        {...register("phone", { required: true })}
                      />
                      {errors.phone && (
                        <Text pt={1} color="error" fontSize="sm" role="alert">
                          This field is required
                        </Text>
                      )}
                    </FormControl>
                  </Stack>

                  <Flex>
                    <Box flex="2" me={{ base: 2, md: 6 }}>
                      <FormControl id="address">
                        <FormLabel>Address</FormLabel>
                        <Autocomplete
                          apiKey={config.googlePlaces.apiKey}
                          onPlaceSelected={(selectedPlace) => {
                            setNewPlace(selectedPlace);
                          }}
                          style={{
                            width: "100%",
                            outline: "2px solid transparent",
                            outlineOffset: "2px",
                            position: "relative",
                            border: "1px solid",
                            padding: "7PX 11px",
                            borderRadius: "8px",
                            borderColor: errors.address ? "red" : "inherit",
                          }}
                          options={{
                            types: ["address"],
                            fields: [
                              "address_components",
                              "geometry",
                              "place_id",
                            ],
                            componentRestrictions: { country: "us" },
                          }}
                          {...register("address")}
                        />
                      </FormControl>
                    </Box>
                    <Box flex="1">
                      <FormControl id="unit">
                        <FormLabel>Unit</FormLabel>
                        <Input {...register("unit")} />
                      </FormControl>
                    </Box>
                  </Flex>
                </Stack>
                <Divider />

                <Flex justify={"center"} pb={8} pt={6}>
                  <Button
                    isLoading={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    Save & Continue
                  </Button>
                </Flex>
              </Box>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Container>
  );
};
export default LandingProfile;
