import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { auth, functions } from "../../../core/firebase";
import { useNavigate, generatePath, useParams, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { verifiedInviteState } from "../../../state/ClientInvite";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";

export const EmailForm = () => {
  const { inviteId } = useParams();
  const verifiedInvite = useRecoilValue(verifiedInviteState);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const confirmInviteEmail = httpsCallable(
        functions,
        "clientConnect-confirmInviteEmail"
      );
      const confirmInviteEmailResult: HttpsCallableResult =
        await confirmInviteEmail({
          inviteId: inviteId,
          email: data.email.toLowerCase().trim(),
        });

      if (confirmInviteEmailResult.data === true) {
        sessionStorage.setItem("email", data.email.toLowerCase().trim());

        const result = await fetchSignInMethodsForEmail(
          auth,
          data.email.toLowerCase().trim()
        );

        if (result.length > 0) {
          const signInPath = generatePath("/invite/:inviteId/sign-in", {
            inviteId: inviteId ?? "",
          });
          navigate(signInPath);
        } else {
          const createAccountPath = generatePath(
            "/invite/:inviteId/create-account",
            { inviteId: inviteId ?? "" }
          );
          navigate(createAccountPath);
        }
      } else {
        setError("root.submissionError", {
          message: "That email doesn't match our records. Please try again.",
        });
      }
    } catch (error) {
      setError("root.submissionError", { message: "An error occurred." });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        maxW="md"
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg-surface" }}
        boxShadow={{ base: "none", sm: "md" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs" }}>Verify Your Account</Heading>
            </Stack>
          </Stack>
          {errors.root && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {errors.root.submissionError.message}
              </AlertDescription>
            </Alert>
          )}
          <Stack spacing="6">
            <FormControl>
              <FormLabel htmlFor="email">
                Please confirm the email "{verifiedInvite?.maskedEmail}"
              </FormLabel>
              <Input
                id="email"
                placeholder="Confirm your email"
                isInvalid={errors?.email ? true : false}
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <Text pt={1} color="error" fontSize="sm" role="alert">
                  This field is required
                </Text>
              )}
            </FormControl>
            <Text fontSize="xs" lineHeight={1.3}>
              By clicking "Continue" you agree to EasyHome's{" "}
              <ChakraLink
                as={Link}
                to="https://www.easyhomeinc.com/terms"
                color="teal.500"
                target="_blank"
              >
                Terms of Use
              </ChakraLink>{" "}
              and{" "}
              <ChakraLink
                color="teal.500"
                as={Link}
                to="https://www.easyhomeinc.com/privacy"
                target="_blank"
              >
                Privacy Policy
              </ChakraLink>
              .
            </Text>
            <Button variant="primary" isLoading={isSubmitting} type="submit">
              Continue
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
